import * as Yup from 'yup';
import { AnySchema } from 'yup';
import { IDelivery, IDeliveryField } from '../models/delivery.model';
import IFormField from '../models/form-field.model';
import useTranslations from '../hooks/use-translations';

export function getDeliveryProvidersField(deliveryProviders: IDelivery[]): IFormField {
    const options: IFormField['options'] = deliveryProviders.map((provider) => {
        return {
            label: `<p class='provider-name'>${provider.name}<br><span class='provider-price'>${
                provider.finalPrice.grossDisplay
            }</span>${provider.shippingTime ? ` • ${provider.shippingTime}` : ''}</p>`,
            image: provider.media,
            value: `${provider.providerId}`,
            enabled: provider.enabled,
        };
    });
    return {
        type: 'radio',
        name: 'delivery.providerId',
        label: '',
        options: options,
    };
}

export function getDeliveryProviderAdditionalFields(deliveryProvider: IDelivery): IFormField[] {
    const { fields } = deliveryProvider;
    if (!fields) return [];
    return fields.map((field) => {
        return {
            name: `delivery.data.${field.name}`,
            label: `${field.label}${field.isRequired ? '*' : ''}`,
            placeholder: field.placeholder || '',
            type: field.type,
            extra: field.extra,
        };
    });
}

export function getSelectedDeliveryProvider(
    selectedProviderId: number | string,
    providers: IDelivery[]
) {
    return providers.find(
        (provider) => provider.providerId.toString() === selectedProviderId.toString()
    );
}

export function getOrderDeliveryValidationSchema(
    selectedProviderId: string | number,
    providers: IDelivery[],
    t: ReturnType<typeof useTranslations<'OrderDeliveryFields'>>
) {
    const selectedProvider = getSelectedDeliveryProvider(selectedProviderId, providers);
    const dataFields = selectedProvider?.fields;
    return Yup.object().shape({
        delivery: Yup.object({
            providerId: Yup.string().required(t.fields.providerId.error.required),
            data:
                dataFields && dataFields.length > 0
                    ? Yup.object(getPartialOrderDeliveryValidationSchema(dataFields, t))
                    : Yup.object(),
        }),
    });
}

export interface IOrderDeliveryFormValues {
    delivery: {
        providerId: string;
        data: Partial<Record<string, any>>;
    };
}

export const initialOrderDeliveryValues: IOrderDeliveryFormValues = {
    delivery: {
        providerId: '',
        data: {},
    },
};

function getPartialOrderDeliveryValidationSchema(
    fields: IDeliveryField[],
    t: ReturnType<typeof useTranslations<'OrderDeliveryFields'>>
) {
    const schema: Record<string, AnySchema> = {};
    fields.forEach((field) => {
        schema[field.name] = field.isRequired
            ? Yup.string().required(t.fields.additional.error.required)
            : Yup.string();
    });
    return schema;
}

export function getOrderDeliveryInitialValues(
    values: IOrderDeliveryFormValues,
    deliveryProviders: IDelivery[]
): IOrderDeliveryFormValues {
    if (!values.delivery.providerId) return values;
    const selectedProvider = getSelectedDeliveryProvider(
        values.delivery.providerId,
        deliveryProviders
    );
    const dataFields = selectedProvider?.fields;
    if (!dataFields || !dataFields.length) {
        return {
            ...values,
            delivery: {
                ...values.delivery,
                data: {},
            },
        };
    }
    return {
        ...values,
        delivery: {
            ...values.delivery,
            data: dataFields.reduce((acc, field) => {
                acc[field.name] = '';
                return acc;
            }, {} as Record<string, string>),
        },
    };
}
