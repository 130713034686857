import React from 'react';
import { Script, GatsbyBrowser } from 'gatsby';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './src/styles/reset.scss';
import './src/styles/variables.scss';
import './src/styles/global.scss';
import './src/styles/toastify.scss';
import store from './src/redux/store';

const API_URL = process.env.API_URL;
const queryClient = new QueryClient();

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
            <Script src="/cookies.js?v=3" data-api-url={`${API_URL}/cookies`} />
            <ToastContainer className="ap-toastify" />
        </Provider>
    );
};

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
    pathname,
    prevRouterProps,
    routerProps,
}) => {
    const prevPathname = prevRouterProps?.location.pathname;
    const preventScroll = routerProps.location.state?.preventScroll;
    return !(prevPathname === pathname && preventScroll);
};
